body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "CallingCode";
  src: url("/public/assets/CallingCode-Regular.otf");
}

@font-face {
  font-family: "Wordan";
  src: url("/public/assets/Wordan 400.ttf");
}

@font-face {
  font-family: 'Larabie';
  src: url('/public/assets/Code-Squared.woff') format('woff');
}

html {
  background-color: #1c1b1b;
}
