@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .left-arrow {
       clip-path: polygon(4% 0%, 0% 50%, 5% 100%, 100% 100%, 95% 0%, 10% 0%);
    }

    .shadow-arrow {
        background: linear-gradient(0.5turn, transparent, #0a8858, transparent);
        clip-path: polygon(60% 0%, 10% 50%, 70% 100%, 95% 100%, 35% 50%, 85% 0%);
        @apply w-full h-full;
    }

    .corners{
        @apply  border-green h-8 w-8 sm:h-20 sm:w-20 md:h-24 md:w-24 relative;
    }

    .shape-full {
        @apply w-full h-full
    }
    .center-div{
        @apply flex justify-center items-center;
    }

    .challenge-vertical-line {
        @apply border-l-2 border-medium-green h-12 sm:h-20 lg:h-24 xl:h-28
    }

    .challenge-horizontal-line {
        @apply border-t-2 border-medium-green w-2 h-0.5 sm:w-5 sm:h-1 lg:h-2 lg:w-6 xl:h-2 xl:w-10
    }

    .challenge-open-brace {
        @apply border-l-4 border-t-4 border-b-4 border-green h-12 w-2.5 sm:h-20 sm:w-5 lg:h-24 lg:w-8 xl:h-28 xl:w-10
        relative left-1 sm:left-2 lg:left-5
    }

    .challenge-close-brace {
        @apply border-r-4 border-t-4 border-b-4 border-green h-12 w-2.5 sm:h-20 sm:w-5 lg:h-24 lg:w-8 xl:h-28 xl:w-10
        relative right-1 sm:right-2 lg:right-5
    }

    .challenge-solution-text {
        @apply font-larabie text-1xs sm:text-sm lg:text-3xl
    }

    .challenge-solution-bg {
        @apply  px-5 py-2 sm:px-24 sm:py-2 sm:w-28 sm:h-14 lg:w-60 lg:h-16 xl:w-80 xl:h-[5.5rem]
    }

    .lets-go-arrow-size {
        @apply relative w-4 h-6 sm:w-[2.7rem] sm:h-14
    }

    .white-circle {
        @apply relative h-1 w-1 md:h-2 md:w-2 rounded-3xl bg-white;
    }

    .white-arc {
        @apply relative border-white border border-b-transparent border-r-transparent border-l-transparent rounded-[9999px];
    }

    .pagination {
        @apply w-4 h-4 border border-green border rotate-45;
    }

    .shadow-arrow-bg {
        background: linear-gradient(0.5turn, transparent, #00ff95, transparent);
        clip-path: polygon(55% 0%, 5% 50%, 65% 100%, 100% 100%, 40% 50%, 90% 0%);
        @apply relative w-3 h-6 sm:w-3 sm:h-7 md:w-6 md:h-14
    }

    /*.triple-left-arrows{*/
    /*    @apply relative;*/
    /*    clip-path: polygon();*/
    /*}*/

    .showcase-gon {
        @apply relative;
        clip-path: polygon(13% 0%, 87% 0%, 95% 5%, 95% 25%,
        100% 28%, 100% 75%, 95% 78%, 95% 90%, 95% 95%, 87% 100%,
        13% 100%, 5% 95%, 5% 78%, 0% 75%, 0% 28%, 5% 25%, 5% 5%);
    }

    .lets-go {
       clip-path: polygon(10% 0%, 90% 0%, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
    }

    .title {
        @apply text-lg sm:text-xl md:text-3xl lg:text-5xl font-larabie sm:mb-3 lg:mb-6;
    }

    .description {
        @apply w-full text-1xs sm:text-sm md:text-lg lg:text-xl;
    }

    .blog-title {
        @apply font-larabie font-normal text-base sm:text-lg md:text-4xl;
    }

    .col-divider {
        @apply mb-10 sm:mb-20 md:mb-44 lg:mb-12;
    }

    .previous-arrow {
        @apply  bg-green w-6 h-12 md:w-10 md:h-16;
        clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    }

    .previous-arrow-bg {
        @apply  bg-dark-green w-[92%] h-[96%];
        clip-path: polygon(52% 0%, 96% 0%, 44% 50%, 96% 100%, 52% 100%, 0% 50%);
    }

    .next-arrow {
        @apply  bg-green  w-6 h-12 md:w-10 md:h-16;
        clip-path: polygon(0% 100%, 50% 100%, 100% 50%, 50% 0%, 0% 0%, 50% 50%);
    }

    .next-arrow-bg {
        @apply  bg-dark-green w-[92%] h-[96%];
        clip-path: polygon(5% 100%, 45% 100%, 98% 50%, 45% 0%, 5% 0%, 58% 50%);
    }

    .num-counter {
        @apply relative;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    }

    .goal-box {
       clip-path: polygon(7% 100%, 30% 100%, 33% 95%, 72% 95%, 75% 100%, 93% 100%,
        100% 85%, 100% 15%,
        80% 0%, 75% 5%, 50% 5%, 46% 10%, 30% 10%, 25% 0%, 10% 0%,
        0% 15%, 0% 85%);
    }

    .goal-box-2 {
        clip-path: polygon(7% 100%, 29.5% 100%, 32.5% 95%, 72.5% 95%, 75.5% 100%, 93% 100%,
        100% 85%, 100% 15%,
        80.5% 0%, 75.5% 5%, 50% 5%, 46% 10%, 29.5% 10%, 24.5% 0%, 10% 0%,
        0% 15%, 0% 85%);
    }

    .goal-extra-1 {
       clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);
    }

    .goal-extra-2 {
       clip-path: polygon(0% 0%, 100% 0%, 93% 40%, 45% 40%, 38% 100%, 8% 100%);
    }

    .small-left-arrow {
       clip-path: polygon(45% 0%, 60% 0%, 15% 50%, 60% 100%, 45% 100%, 0% 50%);
    }

    .small-right-arrow {
       clip-path: polygon(45% 0%, 60% 0%, 100% 50%, 60% 100%, 45% 100%, 85% 50%);
    }

    .font-box{
       clip-path: polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 35%);
    }

    .small-font-box{
       clip-path: polygon(38% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 35%);
    }


    .clippers{
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 100% 100%, 50% 20%, 0 20%, 0 0);
        background-color: red;/* Clip off 80% */
        @apply relative
    }

    .clipper{
        clip-path: polygon(50% 0%, 100% 0, 100% 100%, 50% 100%, 50% 20%, 0 20%, 0 0); /* Clip off 80% */
    }

    @keyframes rcw-loop-one {
        from {
            transform: rotate(90deg); /* Start the rotation from 0 degrees */
        }
        to {
            transform: rotate(450deg); /* End the rotation at 360 degrees (a full circle) */
        }
    }

    @keyframes rotate-clock-wise {
        from {
            transform: rotate(0deg); /* Start the rotation from 0 degrees */
        }
        to {
            transform: rotate(360deg); /* End the rotation at 360 degrees (a full circle) */
        }
    }

    @keyframes rotate-anti-clock-wise {
        from {
            transform: rotate(360deg); /* Start the rotation from 0 degrees */
        }
        to {
            transform: rotate(0deg); /* End the rotation at 360 degrees (a full circle) */
        }
    }

    .donut {
        @apply border-medium-green bg-dark-green center-div flex-col w-[200px] h-[200px] sm:w-[290px] sm:h-[290px]
        before:w-56 before:h-56 after:w-56 after:h-56 sm:before:w-[315px] sm:before:h-[315px] sm:after:w-[315px] sm:after:h-[315px];
        border: 2px dashed #00ff94;
        border-radius: 50%;
        position: relative;
        animation: draw-dashed-border 5s linear infinite; 
    }

    .donut::before,
    .donut::after {
        content: '';
        position: absolute;
        /* top: 1px; */
        bottom: -15px;
        border: solid transparent;
        @apply border-[18px] sm:border-[25px];
        border-radius: 50%;
        opacity: 0.5;
        transform: rotate(30deg);
        animation: rotate-anti-clock-wise 10s linear infinite;
    }

    .donut::before {
        left: -15px;
        border-left-color: lightgreen;
        animation: rotate-clock-wise 10s linear infinite;
    }

    .clips {
        @apply  rounded-[9999px] border border-transparent bg-transparent absolute;
        animation: rotate-clock-wise 5s linear infinite;
    }

    .clips-2 {
        @apply rounded-[9999px] border border-transparent bg-transparent absolute;
        animation: rotate-anti-clock-wise 5s linear infinite;
    }

    .clips-3 {
        @apply rounded-[9999px] border border-transparent bg-transparent absolute;
        animation: rcw-loop-one 5s linear infinite;
    }

    .gradient-circle {
        width: 100px; /* Set the width and height of your circle */
        height: 100px;
        border-width: 1px; /* Adjust the border width as needed */
        border-style: solid; /* Use a solid border style */
        border-radius: 50%; /* Make sure the element is a circle */
        border-image-source: linear-gradient(45deg, transparent, yellow); /* Customize the gradient colors */
        border-image-slice: 1; /* Ensure the gradient covers the entire border */
    }

    .donut::after {
        right: -15px;
        border-right-color: lightgreen;
       /* Make sure the element is a circle *//*animation: rotate-anti-clock-wise 10s linear infinite;*/
    }

    
    @keyframes draw-dashed-border {
        0% {
            stroke-dasharray: 0 100%; /* Start with no visible border */
        }
        100% {
            stroke-dasharray: 100% 100%; /* End with a full circle */
        }
    }

    .box-shadow-top {
        box-shadow: -3px 5px 4px 2px #19C84A;
    }

    .box-shadow-bottom {
        box-shadow: 3px -4px 4px 2px #19C84A;
    }

    .custom-border {
        border: 1px solid transparent; /* Set a transparent border to create space for the gradient */
        border-image: linear-gradient(to bottom,  transparent, transparent, #00ff95) 1; /* Apply the gradient as the border image */
        border-top: none;
    }

    .custom-border-header {
        border: 2px solid transparent; /* Set a transparent border to create space for the gradient */
        border-image: linear-gradient(0.25turn, transparent, #00ff95, transparent) 1; /* Apply the gradient as the border image */
        border-top: none;
    }

    .custom-border-center {
        border: 1px solid transparent; /* Set a transparent border to create space for the gradient */
        border-image: linear-gradient(to bottom,  transparent, #00ff95, transparent) 1; /* Apply the gradient as the border image */
        border-top: none;
    }

    progress::-webkit-progress-bar {
        @apply bg-dark-green;
    }

    progress::-webkit-progress-value {
        @apply bg-green;
    }

    progress::-moz-progress-bar {
        @apply bg-green;
    }

    .letter {
        will-change: transform, opacity;
    }

    /*.water-wave {*/
    /*    position: relative;*/
    /*    width: 200px;*/
    /*    height: 200px;*/
    /*    border-radius: 50%;*/
    /*    overflow: hidden;*/
    /*}*/

    .wave {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid white;
        border-radius: 50%;
    }


    .parallax-image {
        width: 100%;
        height: 100%;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
    }

    .parallax-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .parallax-image.active {
        transform: scale(1.2);
    }

}
